import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';

import LandingHeader from 'components/LandingHeader';
import LandingFeatures from 'components/LandingFeatures';
import LandingHow from 'components/LandingHow';
import LandingDivider from 'components/LandingDivider';
import LandingFaq from 'components/LandingFaq';
import LandingBanner from 'components/LandingBanner';
import LandingBannerIndulgence from 'components/LandingBannerIndulgence';
import LandingVideo from 'components/LandingVideo';
import TextBlock from 'components/common/TextBlock';
import LandingImageBanner from 'components/LandingImageBanner';
import LandingBannerSecond from 'components/LandingBannerSecond';
import Seo from 'components/common/Seo';

import { GUARDIUM_DESIGN } from 'utils/constants';
import { ILandingPageProps } from './models';
import './LandingPage.scss';

const LandingPage: FC<ILandingPageProps> = ({
  data: {
    page: {
      nodes: [pageData],
    },
    siteSettings,
    brandSettings,
  },
}) => {
  const isGuardiumDesign = pageData?.tags?.some((tag) => tag?.name === GUARDIUM_DESIGN);

  const cx = classnames('landing-page', {
    'landing-page-guardium-design': isGuardiumDesign,
  });

  return (
    <>
      <Seo
        siteSettings={siteSettings}
        brandSettings={brandSettings}
        title={pageData.seoMetaTitle}
        description={pageData.seoMetaDescription}
        externalHreflangs={pageData.seoExternalHreflangs}
        keywords={pageData.seoMetaKeywords}
        openGraphImageUrl={pageData.openGraphImageUrl}
      />
      <div className={cx}>
        {pageData.landingBlocks.map((item) => {
          switch (item.structure) {
            case 'Landing Header':
              return <LandingHeader {...item.properties} />;
            case 'Landing Banner':
              return <LandingBanner {...item.properties} />;
            case 'Landing Banner Indulgence':
              return <LandingBannerIndulgence {...item.properties} />;
            case 'Features':
              return <LandingFeatures {...item.properties} />;
            case 'Landing Divider':
              return <LandingDivider {...item.properties} />;
            case 'Landing How':
              return <LandingHow {...item.properties} />;
            case 'Video Component':
              return <LandingVideo {...item.properties} />;
            case 'Landing Faqs':
              return <LandingFaq {...item.properties} />;
            case 'Text Block':
              return <TextBlock text={item.properties.content} />;
            case 'Landing Image Banner':
              return (
                <LandingImageBanner
                  bannerImage={item.properties.bannerImage}
                  bannerImageAlt={item.properties.bannerImageAlt}
                />
              );
            case 'Landing Banner Second':
              return <LandingBannerSecond {...item.properties} />;

            default: {
              return <div>{item.structure}</div>;
            }
          }
        })}
      </div>
    </>
  );
};

export const query = graphql`
  query($link: String = "", $lang: String) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    brandSettings(lang: { eq: $lang }) {
      brandName
      logo {
        ...FragmentGatsbyImage
      }
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: "LP" }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: allLandingPage(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        landingBlocks {
          structure
          properties {
            titleBlock {
              properties {
                subText
                text
                textSecond
                title
              }
            }
            text
            backgroundImage {
              properties {
                altText
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
            brandTitle {
              properties {
                altText
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
            bannerProductImage {
              properties {
                altText
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
            rightImage {
              properties {
                altText
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
            additional
            content
            heading
            heading2
            subHeading
            subHeading2
            finePrint
            image {
              ...FragmentGatsbyImage
            }
            mobileImage {
              ...FragmentGatsbyImage
            }
            productImage {
              ...FragmentGatsbyImage
            }
            bannerImage {
              ...FragmentGatsbyImage
            }
            bannerImageAlt
            leftFeatures {
              properties {
                title
                text
                image {
                  fallbackUrl
                  altText
                }
              }
            }
            rightFeatures {
              properties {
                title
                text
                image {
                  fallbackUrl
                  altText
                }
              }
            }
            youtubeVideoId
            features {
              properties {
                title
                text
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
            questions {
              properties {
                question
                answer
              }
            }
            menuLinks {
              name
              target
              url
              queryString
            }
            link {
              name
              target
              url
              queryString
            }
          }
        }
        tags {
          name
        }
        id
        link
      }
    }
  }
`;

export default LandingPage;
